<template>
	<div class="scc-form">
		<SCCForm1 v-if="activeStep == 1" v-model="form[0]" @click:add-media-social="(index) => $emit('click:add-media-social', index)" @click:delete-media-social="(index) => $emit('click:delete-media-social', index)"/>
		<SCCForm2 v-if="activeStep == 2" v-model="form[1]"/>
		<SCCForm3 v-if="activeStep == 3" v-model="form[2]"/>
	</div>
</template>

<script>
import SCCForm1 from './Form1.vue'
import SCCForm2 from './Form2.vue'
import SCCForm3 from './Form3.vue'

export default {
	props: {
		activeStep: {
			type: Number,
			default: 1,
		},
		form: {
			type: Array,
			default : () => {
				return []
			}
		}
	},
	components: {
		SCCForm1,
		SCCForm2,
		SCCForm3
	}
}
</script>