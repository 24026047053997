<template>
	<div class="scc-step d-flex flex-column">
		<div class="scc-step__header d-flex align-items-center justify-content-center">
            <img src="@/assets/images/icons/ic_soca-cc.svg" alt="Soca Certified Creator" title="Soca Certified Creator" class="scc-step__icon">
            <h4 class="scc-step__header-title">Soca Certified Creator</h4>
        </div>
		<p class="scc-step__header-subtitle">Please complete the form below to become a Soca Certified Creator</p>
		<div class="scc-step__form">
			<div v-for="(step, index) in steps" :key="index" class="scc-step__form-wrapper d-flex flex-column">
				<div class="scc-step__name d-flex align-items-center">
					<div class="scc-step__numbering d-flex align-items-center justify-content-center" :class="{ 'scc-step__active' : activeStep >= index+1 }">
						<font-awesome-icon
							v-if="activeStep > index+1"
							icon="fa-solid fa-check"
						/>
						{{ activeStep > index+1 ? '' : index + 1 }}
					</div>
					<p class="scc-step__numbering-label m-0" :class="{ 'scc-step__label-active' : activeStep >= index+1 }">{{ step }}</p>
				</div>
				<div class="scc-step__underline" :class="{ 'scc-step__active' : activeStep >= index+1 }"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		steps: {
			type: Array,
			default: () => {
				return []
			}
		},
		activeStep: {
			type: Number,
			default: 1,
		}
	},
	data() {
		return {

		}
	},
}
</script>

<style scoped>
.scc-step {
	gap: 20px;
}

.scc-step__header {
	gap: 6px;
}

.scc-step__icon {
	width: 34.204px;
	height: 34.204px;
}

.scc-step__header-title {
	color: #FFF;
	font-size: 23.057px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.scc-step__header-subtitle {
	color: #FFF;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.scc-step__form {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Membagi parent menjadi 3 kolom dengan lebar yang sama */
	gap: 24px;
}

.scc-step__form-wrapper {
	gap: 8px;
}

.scc-step__name {
	gap: 10px;
}

.scc-step__numbering {
	font-size: 14px;
	width: 41px;
	height: 41px;
	border-radius: 50%;
}

.scc-step__numbering, .scc-step__underline {
	background-color: #2D2D2D;
	transition: background-color 0.25s, color 0.25s;
}

.scc-step__numbering, .scc-step__numbering-label {
	color: #8C8C8C;
	transition: color 0.25s;
}

.scc-step__numbering-label {
	font-family: Avenir Next;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 31.948px;
}

.scc-step__underline {
	width: 100%;
	border-radius: 100px;
	height: 5px;
}

.scc-step__active {
	background-color: #6D75F6;
	color: #fff;
}

.scc-step__label-active {
	color: #6D75F6;
}
</style>