<template>
	<div class="scc-form-2 mb-5">
		<div class="container-fluid">
			<div class="row">
				<div class="col-5 pr-5">
					<p class="scc-form-2__title text-left">Verification Documents</p>
					<div class="scc-form-2__wrapper d-flex flex-column">
						<div class="scc-form-2__verifdoc d-flex align-items-center">
							<div class="d-flex align-items-center">
								<input class="scc-form-2__checkbox" v-model="formData.verificationDocumentType" type="radio" name="verifDocs" id="idCardCheck" value="ID Card" checked>
								<label class="scc-form-2__label mb-0 ml-2" for="idCardCheck">
									ID Card
								</label>
							</div>
							<div class="d-flex align-items-center">
								<input class="scc-form-2__checkbox" v-model="formData.verificationDocumentType" type="radio" name="verifDocs" id="passportCheck" value="Passport">
								<label class="scc-form-2__label mb-0 ml-2" for="passportCheck">
									Passport
								</label>
							</div>
						</div>
						<label for="uploadVerifDoc" class="scc-form-2__upload-doc cursor-pointer d-flex flex-column justify-content-center align-items-center">
							<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
								<path d="M8.62674 10.9296L8.62674 1" stroke="#6D75F6" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M11.0312 8.51562L8.62657 10.9302L6.22189 8.51562" stroke="#6D75F6" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M12.4504 4.90234H13.2198C14.898 4.90234 16.2578 6.26219 16.2578 7.94118V11.9688C16.2578 13.6428 14.9013 14.9994 13.2272 14.9994L4.04062 14.9994C2.36246 14.9994 1.00179 13.6387 1.00179 11.9605L1.00179 7.93211C1.00179 6.25889 2.35916 4.90234 4.03238 4.90234L4.8092 4.90234" stroke="#6D75F6" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
							<p class="scc-form-2__upload-doc-text">Upload Photo</p>
						</label>
						<input type="file" id="uploadVerifDoc" class="d-none">
					</div>
				</div>
				<div class="col-7 pl-4">
					<p class="scc-form-2__title text-left">Bank Account</p>
					<div class="scc-form-2__wrapper d-flex flex-column">
						<label for="bankname" class="scc-form-2__label text-left m-0">Bank Name*</label>
						<select
							id="bankname"
							class="scc-form-2__select mb-2"
							title="Role/Position"
							v-model="formData.bankName"
						>
							<option value="Bank Mandiri" class="scc-form-2__option">Bank Mandiri</option>
							<option value="Bank BCA" class="scc-form-2__option">Bank BCA</option>
							<option value="Bank BRI" class="scc-form-2__option">Bank BRI</option>
							<option value="Bank BNI" class="scc-form-2__option">Bank BNI</option>
						</select>
						<label for="accountHolderName" class="scc-form-2__label text-left m-0">Account Holder Name*</label>
						<input type="text" v-model="formData.accountHolderName" id="accountHolderName" required autocomplete="false" class="scc-form-2__input mb-2" placeholder="John Doe">
						<label for="accountNumber" class="scc-form-2__label text-left m-0">Account Number*</label>
						<input type="number" v-model="formData.accountNumber" id="accountNumber" required autocomplete="false" class="scc-form-2__input mb-2" placeholder="1300011223344">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		value: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			formData : this.value
		}
	},
	watch: {
		formData: {
			handler(newForm1Data) {
				this.$emit('input', newForm1Data);
			},
			deep: true,
		},
	},
}
</script>

.<style scoped>
.scc-form-2__title {
	color: #FFF;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.scc-form-2__wrapper {
	gap: 12px;
}

.scc-form-2__label {
	font-size: 14px;
}

.scc-form-2__verifdoc {
	gap: 38px;
}

.scc-form-2__checkbox {
	appearance: none;
	width: 16px;
	height: 16px;
	border: 1px solid #FFF;
	border-radius: 50%;
	background-clip: content-box;
	padding: 2px;
}

.scc-form-2__checkbox:checked {
	border: 1px solid #6D75F6;
	background-color: #6D75F6;
}

.scc-form-2__upload-doc {
	height: 124px;
	border-radius: 4px;
	border: 1px dashed #6D75F6;
	gap: 6px;
}

.scc-form-2__upload-doc-text {
	color: #6D75F6;
	font-size: 10px;
}

.scc-form-2__input, .scc-form-2__select {
	background-color: transparent;
	border-radius: 4px;
	border: 1px solid #2D2D2D;
	font-size: 12px;
	color: #FFF;
	padding: 12px 10px;
}

.scc-form-2__input::placeholder {
	color: #585859;
}

.scc-form-2__option {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.scc-form-2__select {
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('~@/assets/images/icons/ic_chevron-bottom.svg');
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 24px;
}

.scc-form-2__select::-ms-expand {
  display: none;
}

.scc-form-2__input::-webkit-inner-spin-button,
.scc-form-2__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.scc-form-2__input {
    -moz-appearance: textfield;
    appearance: textfield;
}

</style>