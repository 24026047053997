<template>
	<div class="scc d-flex flex-column text-white">
		<SCCHeader @click:back="$router.go(-1)"/>
		<main class="scc__main mx-3">
			<div class="scc__container d-flex flex-column flex-grow-1 py-4">
				<SCCStep :steps="step" :activeStep="activeStep"/>
				<SCCForm :activeStep="activeStep" :form="form" @click:add-media-social="handleClickAddMediaSocial" @click:delete-media-social="handleClickDeleteMediaSocial"/>
				<div class="scc__action d-flex align-items-center justify-content-center">
					<button class="btn scc-button scc-button--back" @click="handleCancel">{{ activeStep == 1 ? 'Cancel' : 'Back' }}</button>
					<button :disabled="disabledNextButton" class="btn scc-button scc-button--next" @click="handleClickNext">{{ activeStep == 3 ? 'Submit' : 'Next' }} <font-awesome-icon class="scc-button--icon" :icon="activeStep === 3 ? 'fa-solid fa-check' : 'fa-solid fa-chevron-right'" /></button>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import SCCHeader from '@/components/soca-certified-creator/header';
import SCCStep from '@/components/soca-certified-creator/step-form';
import SCCForm from '@/components/soca-certified-creator/form';

export default {
	components: {
		SCCHeader,
		SCCStep,
		SCCForm
	},

	computed: {
		disabledNextButton() {
			if(
				this.activeStep == 1 &&
				(
					!this.form[0].nameOfInstitution
					|| !this.form[0].role
					|| !this.form[0].email
					|| !this.form[0].institutionWebsite
					|| this.checkSocialMedia()
				)
			) {
				return true
			} else if (
				this.activeStep == 2 &&
				(
					!this.form[1].verificationDocumentType
					|| !this.form[1].bankName
					|| !this.form[1].accountHolderName
					|| !this.form[1].accountNumber
				)
			) {
				return true
			} else if (
				this.activeStep == 3 &&
				(
					!this.form[2].reasonJoinSCC
					|| !this.form[2].isVerify
				)
			) {
				return true
			} else {
				return false
			}
		}
	},

	data() {
		return {
			activeStep: 1,
			step: ['Profile', 'Verification', 'Submit'],
			form: [
				{
					nameOfInstitution: null,
					role: 'Teacher / Professor',
					email: null,
					institutionWebsite: null,
					socialMedia: [
						{
							media: 'Instagram',
							link: null
						}
					]
				},
				{
					verificationDocumentType: "ID Card",
					file: null,
					bankName: "Bank Mandiri",
					accountHolderName: null,
					accountNumber: null,
				},
				{
					reasonJoinSCC: null,
					isVerify: null
				}
			]
		}
	},

	methods: {
		handleCancel() {
			if(this.activeStep > 1) {
				this.activeStep--
			}
		},

		handleClickAddMediaSocial(index) {
			let newMedia = {
				media: this.form[0].socialMedia[index].media,
				link: null
			}

			this.form[0].socialMedia.splice(index + 1, 0, newMedia);
		},

		handleClickDeleteMediaSocial(index) {
			this.form[0].socialMedia.splice(index, 1);
		},

		checkSocialMedia() {
			return this.form[0].socialMedia.some(item => item.link === null);
		},

		handleClickNext() {
			if(this.activeStep < 3) {
				this.activeStep++
			}
		}
	},
}
</script>

<style scoped>
.scc {
	height: 100vh;
}

.scc__main {
	display: contents;
}

.scc__container {
	width: 880px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
	min-height: 0;
	gap: 22px;
}

.scc__action {
	gap: 16px;
}

.scc-button {
	width: 172px;
	border-radius: 6px;
	color: #FFF;
	font-size: 18px;
	font-weight: 600;

	transition: background-color 0.25s;
}

.scc-button--next {
	background-color: #6D75F6;
}

.scc-button--next:not(:disabled):hover {
	background-color: #545EED;
}

.scc-button--next:disabled {
	background-color: #585859;
	color: #8C8C8C;
}

.scc-button--back {
	background-color: #1F1F1F;
}

.scc-button--back:hover {
	background-color: #333333;
}

.scc-button--icon {
	font-size: 12px;
}
</style>