<template>
	<div class="scc-form-1 d-flex align-items-center">
		<div class="container-fluid">
			<div class="row">
				<div class="col-6 pr-5">
					<p class="scc-form-1__title text-left">School/University</p>
					<div class="scc-form-1__wrapper d-flex flex-column">
						<label for="nameInstitution" class="scc-form-1__label text-left m-0">Name of Institution*</label>
						<input type="text" id="nameInstitution" required autocomplete="false" class="scc-form-1__input mb-2" placeholder="Soca SCH" v-model="formData.nameOfInstitution">
						<label for="role" class="scc-form-1__label text-left m-0">Role/Position*</label>
						<select
							id="role"
							class="scc-form-1__select mb-2"
							title="Role/Position"
							v-model="formData.role"
						>
							<option value="Headmaster / Dean" class="scc-form-1__option">Headmaster / Dean</option>
							<option value="Teacher / Professor" class="scc-form-1__option">Teacher / Professor</option>
							<option value="Administration Staff" class="scc-form-1__option">Administration Staff</option>
							<option value="Counselor" class="scc-form-1__option">Counselor</option>
						</select>
						<label for="email" class="scc-form-1__label text-left m-0">Official Email*</label>
						<input type="email" id="email" required autocomplete="false" class="scc-form-1__input mb-2" placeholder="soca@sch.id" v-model="formData.email">
						<label for="institutionWebsite" class="scc-form-1__label text-left m-0">Institution Website*</label>
						<input type="text" id="institutionWebsite" required autocomplete="false" class="scc-form-1__input mb-2" placeholder="soca.sch.id" v-model="formData.institutionWebsite">
					</div>
				</div>
				<div class="col-6 pl-5">
					<p class="scc-form-1__title text-left">Online Presence</p>
					<div class="scc-form-1__wrapper d-flex flex-column">
						<label class="scc-form-1__label text-left m-0">Social Media Platform*</label>
						<div v-for="(data, index) in formData.socialMedia" :key="index" class="scc-form-1__sub-2 d-flex align-items-center">
							<select
								class="scc-form-1__select"
								title="Role/Position"
								v-model="data.media"
							>
								<option value="Instagram" class="scc-form-1__option">Instagram</option>
								<option value="Facebook" class="scc-form-1__option">Facebook</option>
								<option value="LinkedIn" class="scc-form-1__option">LinkedIn</option>
								<option value="Tiktok" class="scc-form-1__option">Tiktok</option>
								<option value="Youtube" class="scc-form-1__option">Youtube</option>
								<option value="Other" class="scc-form-1__option">Other</option>
							</select>
							<input type="text" id="nameInstitution" required autocomplete="false" class="scc-form-1__input ml-2 w-100" :placeholder="placeholderSocialMedia(data.media)" v-model="data.link">
							<button class="btn scc-form-1__button scc-form-1__button-delete btn" @click="$emit('click:delete-media-social', index)" v-if="formData.socialMedia.length > 1" title="Delete this field">
								<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
									<path d="M12.7863 6.08398C12.7863 6.08398 12.3754 11.1809 12.137 13.3278C12.0235 14.3533 11.39 14.9542 10.3525 14.9731C8.37806 15.0086 6.40136 15.0109 4.42769 14.9693C3.4295 14.9489 2.80668 14.3404 2.69543 13.3331C2.45553 11.1673 2.04688 6.08398 2.04688 6.08398" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M13.8334 3.64011H1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M11.3605 3.64039C10.7665 3.64039 10.2549 3.22038 10.1383 2.63842L9.95444 1.71818C9.84093 1.29363 9.45649 1 9.01831 1H5.81488C5.37671 1 4.99226 1.29363 4.87875 1.71818L4.69485 2.63842C4.57831 3.22038 4.06673 3.64039 3.47266 3.64039" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</button>
							<button class="btn scc-form-1__button scc-form-1__button-add btn" @click="$emit('click:add-media-social', index)" :disabled="formData.socialMedia.length == 6" title="Add new field">
								<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
									<path d="M7.5 1V14" stroke="white" stroke-width="2" stroke-linecap="round"/>
									<path d="M14 7.5L1 7.5" stroke="white" stroke-width="2" stroke-linecap="round"/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props : {
		value: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			formData : this.value
		}
	},
	watch: {
		formData: {
			handler(newForm1Data) {
				this.$emit('input', newForm1Data);
			},
			deep: true,
		},
	},
	methods: {
		placeholderSocialMedia(media){
			let placeholderMap = {
				Instagram: "www.instagram.com",
				Youtube: "www.youtube.com",
				Tiktok: "www.tiktok.com",
				Facebook: "www.facebook.com",
				LinkedIn: "www.linkedin.com",
				Other: "www.yoursocialmedia.com",
			}

			return placeholderMap[media];
		}
	}
}
</script>

<style scoped>
.scc-form-1__title {
	color: #FFF;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.scc-form-1__wrapper {
	gap: 6px;
}

.scc-form-1__label {
	font-size: 14px;
}

.scc-form-1__input, .scc-form-1__select {
	background-color: transparent;
	border-radius: 4px;
	border: 1px solid #2D2D2D;
	font-size: 12px;
	color: #FFF;
	padding: 12px 10px;
}

.scc-form-1__input::placeholder {
	color: #585859;
}

.scc-form-1__option {
  background-color: #2D2D2D;
  color: #FFFFFF;
}

.scc-form-1__sub-2 {
	gap: 8px;
}

.scc-form-1__button {
	border-radius: 4px;
	background-color: #1F1F1F;
	padding: 10px 14px;

	transition: background-color 0.25s;
}

.scc-form-1__button-delete:hover {
	background-color: #F34D4D;
}

.scc-form-1__button-add:not(:disabled):hover {
	background-color: #43e731;
}

.scc-form-1__select {
	-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('~@/assets/images/icons/ic_chevron-bottom.svg');
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 24px;
}

.scc-form-1__select::-ms-expand {
  display: none;
}
</style>